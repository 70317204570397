<template>
  <div>
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'customers-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col-auto">Nº</th>
          <th scope="col-2">Référence Nº</th>
          <th scope="col-4">Nom Complet</th>
          <th scope="col-1">Phone</th>
          <th scope="col-3">Type</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(customer, index) in customers" :key="index++">
          <td class="col-auto">
            {{ index }}
          </td>
          <td class="col-2">
            {{ customer.reference }}
          </td>
          <td class="col-4">
            {{ customer.fullName }}
          </td>

          <td class="col-2">
            {{ customer.phone }}
          </td>
          <td class="col-3">
            <span v-if="customer.type">
              {{ customer.type.fullName }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="$router.push({ name: 'users-create', params: {} })"
              class=""
            >
              Access
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("customer/getSellers");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("customer/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("customer/getSellers");
    },

    async listCustomers(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("customer/getTodays");
      } else {
        return await this.$store.dispatch("customer/getCustomers");
      }
    },
    async sort(value) {
      return await this.$store.commit("customer/sort", value);
    },
  },
  filters: {},
};
</script>
